@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

.delivery{
    background: url("/src/images/altynkur_bg_delivery.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: fit-content;
    /*color: white;*/
}

.delivery_mask{
    background: rgba(255, 255, 255,.7);
    height: inherit;

}
.delivery h2{
    margin: 0;

}
.delivery_text h2{
    font-size: 42px;
    padding: 70px 0 0 0;
    font-family: Rubik, sans-serif;
    text-align: center;
    width: 70%;
    margin: 0 auto;
}
.delivery_box{
    display: grid;
    height: fit-content;
    grid-template-columns: repeat(4, 1fr);
    /*grid-auto-rows: minmax(50px, auto);*/
    padding: 80px 0;
}
.delivery_block{
    background: white;
    border-bottom: 3px solid #C8C8C8;
    box-shadow: 0 0 30px rgb(1 41 112 / 15%);
    transition: 0.3s;
    border-radius: 5px;
    width: 200px;
    height: 300px;
    padding: 30px 30px 0;
    /*margin: 0 50px;*/
    text-align: center;
}
.delivery_block img{
    width: 200px;
    height: 230px;
    object-fit: cover;
}
.delivery_block p{
    font-family: Rubik, sans-serif;
}
@media (max-width: 420px) {

    .delivery{
        background-size: cover;
    }


    .delivery_box{
        display: grid;
        grid-template-columns: repeat(3, 120px);
        grid-column-gap: 5px;
        grid-row-gap: 5px;
        margin: 0;
        padding: 0;
    }
    .delivery h2{
        font-size: 12px;
        padding: 20px 0;
    }
    .delivery_block{

        width: 100px;

       height: 100px;
        padding: 5px 5px 0;
}
    .delivery_block img{
       height: 50px;
       width: fit-content;
    }
    .delivery_block p{
        font-size: 10px;
    }
}
@media (min-width: 430px) and (max-width: 768px) {
    .delivery_box{
        display: grid;
        grid-template-columns: repeat(3, 230px);
        grid-column-gap: 5px;
        grid-row-gap: 5px;
        margin: 0;
        padding: 0;
    }
    .delivery_block{
        padding: 15px 15px 0;
    }
}
@media (min-width: 800px) and (max-width: 820px) {
    .delivery_box{
        display: grid;
        grid-template-columns: repeat(3, 260px);
        grid-column-gap: 5px;
        grid-row-gap: 5px;
        margin: 0;
        padding: 0;
    }
}
