.repair{
    background: url("/src/images/repair.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 100vh;
    width: 100vw;

}
.repair p{
    color: white;
    font-size: 28px;
    display: flex;
    justify-content: center;



}
.repair_mask{
    position: absolute;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(rgba(0, 0, 0, .3), rgba(0, 0, 0, .3));
    align-self: center;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;

}
