@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

.main_bg {
    background: url("/src/images/altynkur_bg_6.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: fit-content;
}
.main_bg_mask{
    background: rgba(0,0,0,.5);

    height: inherit;

    padding: 5% 10%;
    color: white;
}

.main_bg_mask h1{
    margin: 0;
    font-size: 22px;
    font-family: Rubik, sans-serif;

}
.main_bg_mask h2{
    width: 70%;
    font-size: 36px;
    font-family: Montserrat, sans-serif;
    /*letter-spacing: -2px;*/
    text-transform: uppercase;
    font-weight: 700;
}
.main_bg_mask p{
    width: 70%;
    font-size: 36px;
    font-family: Montserrat, sans-serif;
    /*letter-spacing: -2px;*/
    text-transform: uppercase;
    font-weight: 700;
}
.main_icons{
    display: flex;

    align-items: end;
    margin-top: 200px;
    justify-content: center;

}
.main_icons p{
    font-size: 12px;
    font-family: Montserrat, sans-serif;

}
.main_icons_box{
    width: 120px;
    height: 150px;
    margin: 0 5px;
    padding: 10px;
    background-color: grey;
    border-radius: 5px;
}
.main_icons_box p{
    margin: 20px 0;
}
.main_icons_box img{
    padding: 10px 20px;
    width: 50px;
}

@media (max-width: 420px) {
    .main_bg {

        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        /*width: 380px;*/
    }
    .main_bg_mask{
        padding: 20px 0 10px 0;
        /*width: 370px;*/
    }
    .main_bg_mask h1{
        display: flex;
        justify-content: center;
        /*margin: 0 auto;*/
        font-size: 8px;
        padding: 10px 0;


    }
    .main_bg_mask h2{
        width: 70%;
        font-size: 10px;
        font-family: Montserrat, sans-serif;
        text-transform: uppercase;
        font-weight: 700;
        margin: 0 auto;
        padding: 10px 0;
    }
    .main_bg_mask p{
        width: 70%;
        font-size: 10px;
        font-family: Montserrat, sans-serif;
        text-transform: uppercase;
        font-weight: 700;
        margin: 0 auto;
        padding: 10px 0;
    }
    .main_icons{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 5px;
        grid-row-gap: 5px;
        width: fit-content;
        margin: 0 auto;


    }
    .main_icons_box{
        width: 70px;

        height: 70px;
        padding: 0;
        margin: 0;
    }
    .main_icons_box img{
        height: 20px;
        width: fit-content;
        padding: 5px 20px;
    }
    .main_icons_box p{
        font-size: 5px;
        margin: 0 auto;
    }
    .MuiContainer-root{
        padding: 0;
    }

}
@media (max-width: 768px) {}
@media (min-width: 768px) and (max-width: 820px) {
    .logo-box{
        margin: 0;
    }
    .main_icons{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 5px;
        grid-row-gap: 5px;
        width: fit-content;
        margin: 0 auto;


    }
}
@media (min-width: 1200px) {}
