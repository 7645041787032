.search{
    background: url("/src/images/altynkur_bg_2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: fit-content;
}
.search h2{
    font-size: 42px;
    padding: 70px 0 0 0;
    font-family: Rubik, sans-serif;
    text-align: center;
    width: 70%;
    margin: 0 auto;
    color: white;

}
.search p{
    font-size: 28px;
    padding: 70px 0 0 0;
    font-family: Rubik, sans-serif;
    text-align: center;
    width: 70%;
    margin: 0 auto;
    color: white;

}
.search_mask{
    background: rgba(0, 0, 0,.7);

    height: inherit;
}
.search_button{
    width: fit-content;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 50px 0;
}
.css-e53awj-MuiStack-root{
    display: flex;
    justify-content: center;
    padding: 50px 0;
}
@media (max-width: 420px) {
    .search h2{
        font-size: 16px;
        padding-top: 20px;
    }
    .search p{
        font-size: 16px;
        padding-top: 30px;
    }

}

