


.guarantee h2{
    font-size: 42px;
    padding: 70px 0 0 0;
    font-family: Rubik, sans-serif;
    text-align: center;
    width: 70%;
    margin: 0 auto;
}
.guarantee_box{
    display: grid;
    height: fit-content;
    grid-template-columns: repeat(3, 350px);
    /*grid-auto-rows: minmax(50px, auto);*/
    padding: 80px 0;
    grid-column-gap: 10px;
    justify-content: center;

}
.guarantee_icons{
    display: flex;
    justify-content: center;

}
.guarantee_icons_box{
    border: 1px solid #1976D2;
    border-radius: 5px;
}
.guarantee_box h3{
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
}
@media (max-width: 420px) {
    .guarantee h2{
        font-size: 16px;
        padding: 20px;
    }
  .guarantee_box{
      grid-template-columns: repeat(3, 120px);
      padding: 20px 0;
  }
    .guarantee_icons svg{
        font-size: 50px;
    }
    .guarantee_box h3{
        font-size: 8px;
    }
}
@media (min-width: 768px) and (max-width: 820px) {
    .guarantee_box{
        grid-template-columns: repeat(3, 250px);
        padding: 20px 0;
    }
}
