
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.footer {
    background: url("/src/images/altynkur_bg_5.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;

}
.footer-mask{
    background: rgba(0,0,0,.3);
    height: inherit;
    display: flex;
    align-items: center;

}
.contacts {

    color: white;
    height: inherit;

    width: 100%;
    display: flex;
    align-items: end;
    margin: 0 auto;
}


.tel{
    width: 100%;
    background: rgba(1, 100, 168, .8);
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;

}
.tel p{

    font-size: 24px;
}
.tel h3{
    display: flex;
    justify-content: center;
    font-family: 'Rubik', sans-serif;
}
.tel a{
    display: flex;
    justify-content: center;
    font-family: 'Rubik', sans-serif;
    margin: 17px 10px;
}
.tel img{
    width: 120px;

    height: 75px;
    /*border-radius: 5%;*/
    background-color: rgba(0, 0, 0, 0);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    /*margin: 0 auto;*/
}
@media (max-width: 420px) {
    .footer {
        background: url("/src/images/altynkur_bg_5.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        height: 300px;

    }
    .tel{
        height: 50px;
    }
    .tel h3{
        font-size: 6px
    }
    .tel a{
        font-size: 6px;
    }
    .tel img{
        width: 70px;

        height: 50px;
    }
}
@media (max-width: 768px) {}
@media (min-width: 768px) and (max-width: 820px) {
    .footer{
        height: 750px;
    }
}
@media (min-width: 1200px) {}
