
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.logo{

    /*position: sticky;*/
    /*background: rgba(21, 21, 21, 1);*/
    background: rgba(0,0,0,.5);
    top: 0;
    /*z-index: 999;*/

    opacity: 92;
    width: 100%;
    box-shadow: 0 0 50px rgba(73,70,77,0.08);

}
.logo-section{
    display: flex;
    height: 120px;


}
.logo-box{
    display: flex;
    justify-content: space-between;
    margin: 0 150px;
}

.logo-text{

    height: 120px;
    width: 600px;
    display: flex;
    /*text-transform: lowercase;*/
    justify-content: space-around;
    font-weight: bold;

}
.logo-text a{
    font-family: 'Rubik', sans-serif;
    height: 120px;
    align-items: center;
    display: flex;
    color: white;
    text-decoration: none;
}
.logo-text :hover {
    color: #E6D801;
    cursor: pointer;
    /*border-bottom: 4px solid #F33009;*/


}
.logo-img{
    margin-top: 15px;

}
.logo-img img{
    width: 120px;
    height: 75px;
}
.translate {
    background: transparent;
    border: none;
    margin-left: 12px;
    cursor: pointer;
}
.translate img{
    width: 40px;
    height: 30px;
}
.header-item-lang-en{
    list-style-type: none;
}
.header-item-lang-ru{
    list-style-type: none;
}
.language-button{
    display: flex;
    align-items: center;

}
.language-button button{
    background: none;
    color: #44AA04;
    border: solid 1px #44AA04;
    border-radius: 5px;
    margin-right: 4px;
}
@media (max-width: 768px) {
    .logo{


        /*background: rgba(0,0,0,.5);*/
        /*top: 0;*/
        /*z-index: 999;*/

        /*opacity: 92;*/
        /*width: 370px;*/
        height: 50px;
        /*margin: 0;*/
        /*box-shadow: 0 0 50px rgba(73,70,77,0.08);*/

    }
    .logo-box{
        /*width: 350px;*/
        height: 50px;
        margin: 0;
        display: flex;
        /*justify-content: start;*/
    }
    .logo-img{
        margin: 0;
    }
    .logo-img img{
        width: 50px;
        height:50px;

    }
    .logo-text{
        width: 250px;
        height: 50px;
        display: flex;
        justify-content: start;
    }
    .logo-text a{
        font-size: 6px;
        margin: 0 5px;

    }
    .logo-section{
        /*display: flex;*/
        height: 50px;


    }
    .logo-text a{
        height: 50px;
    }
}
@media (min-width: 768px) and (max-width: 1200px) { }
@media (min-width: 1200px) {}
